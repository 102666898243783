// src/components/MentorshipProgram.js
import React from 'react';
import '../css/MentorshipProgram.css';

function MentorshipProgram() {
  return (
    <div className="mentorship-page">
      <h1>Mentorship Program</h1>
      <p>Connect with mentors who can guide you through your academic and professional journey.</p>
      <div className="mentorship-info">
        <h2>How it Works</h2>
        <p>Submit an application to be matched with a mentor in your area of interest.</p>
      </div>
    </div>
  );
}

export default MentorshipProgram;
