import React, { useState } from "react";
import '../../css/Mentor/CommunicationPanel.css';   // Import CSS for Communication Panel

const CommunicationPanel = () => {
  const [message, setMessage] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("Alice Johnson");

  const handleSend = () => {
    alert(`Message sent Successfully`);
    setMessage(""); // Clear the message after sending
  };

  return (
    <section className="communication-panel">
      <h2>Message Your Mentees</h2>
      <select
        value={selectedStudent}
        onChange={(e) => setSelectedStudent(e.target.value)}
      >
        <option value="Alice Johnson">Alice Johnson</option>
        <option value="Bob Williams">Bob Williams</option>
      </select>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here..."
      />
      <button className="send-message-btn" onClick={handleSend}>
        Send Message
      </button>
    </section>
  );
};

export default CommunicationPanel;
