import React, { useState } from "react";
import './../css/NotificationPreferences.css';

const NotificationPreferences = () => {
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [smsNotifications, setSmsNotifications] = useState(false);

  const handleSavePreferences = () => {
    console.log("Preferences Updated", { emailNotifications, smsNotifications });
    alert("Notification preferences updated successfully!");
  };

  return (
    <section className="notification-preferences">
      <h2>Notification Preferences</h2>
      <div className="preferences-form">
        <div className="preference-option">
          <input
            type="checkbox"
            id="email-notifications"
            checked={emailNotifications}
            onChange={() => setEmailNotifications(!emailNotifications)}
          />
          <label htmlFor="email-notifications">Receive notifications via Email</label>
        </div>

        <div className="preference-option">
          <input
            type="checkbox"
            id="sms-notifications"
            checked={smsNotifications}
            onChange={() => setSmsNotifications(!smsNotifications)}
          />
          <label htmlFor="sms-notifications">Receive notifications via SMS</label>
        </div>

        <button className="save-preferences-btn" onClick={handleSavePreferences}>
          Save Preferences
        </button>
      </div>
    </section>
  );
};

export default NotificationPreferences;
