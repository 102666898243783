import React from "react";
import '../../css/Mentor/SessionManagement.css';  // Import CSS for Session Management

const SessionManagement = () => {
  const sessions = [
    { date: "2024-10-20", student: "Alice Johnson", topic: "Career Advice", status: "Upcoming" },
    { date: "2024-10-12", student: "Bob Williams", topic: "Resume Review", status: "Completed" }
  ];

  const handleReschedule = () => {
    alert('Rescheduled Successfully')
  };

  return (
    <section className="session-management">
      <h2>Manage Your Sessions</h2>
      <div className="sessions-list">
        {sessions.map((session, index) => (
          <div key={index} className={`session-card ${session.status.toLowerCase()}`}>
            <h3>{session.date} - {session.student}</h3>
            <p>Topic: {session.topic}</p>
            <p>Status: {session.status}</p>
            {session.status === "Upcoming" && (
              <button className="reschedule-btn" onClick={handleReschedule}>Reschedule</button>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default SessionManagement;
