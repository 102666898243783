import React from "react";
import MentorList from "./MentorList";
import RequestForm from "./RequestForm";
import MessagePanel from "./MessagePanel";
import SessionHistory from "./SessionHistory";
import '../../css/Student/Studentmentorship.css'; 

const MentorshipPage = () => {
    const handleBrowse = () => {
        alert('Browsing Mentors')
      };

  return (
    <div className="student-mentorship-page">
      {/* Hero Section */}
      <section className="mentorship-hero">
        <h1>Find Your Mentor</h1>
        <p>Connect with experienced mentors to guide your career and support your growth.</p>
        <button className="find-mentor-btn" onClick={handleBrowse}>Browse Mentors</button>
      </section>

      {/* Mentor Profiles */}
      <MentorList />

      {/* Mentorship Request Form */}
      <RequestForm />

      {/* Communication Panel */}
      <MessagePanel />

      {/* Session History */}
      <SessionHistory />
    </div>
  );
};

export default MentorshipPage;
