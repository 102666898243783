// src/components/CareerDevelopment.js
import React from 'react';
import '../css/CareerDevelopment.css';

function CareerDevelopment() {
  return (
    <div className="career-development-page">
      <h1>Career Development Resources</h1>
      <p>Explore articles, tips, and tools to advance your career. Join the mentorship program for guidance from experienced professionals.</p>
      <div className="resources-list">
        <div className="resource-card">
          <h2>Articles</h2>
          <p>Read the latest articles on career growth and development.</p>
        </div>
        <div className="resource-card">
          <h2>Mentorship Program</h2>
          <p>Learn more about the mentorship program and how to apply.</p>
        </div>
      </div>
    </div>
  );
}

export default CareerDevelopment;
