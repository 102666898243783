import React, { useState } from "react";
import '../../css/Student/RequestForm.css'; 

const RequestForm = () => {
  const [guidanceType, setGuidanceType] = useState("");
  const [mentorSelect, setMentorSelect] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Mentorship Requested");
  };

  return (
    <section className="request-form">
      <h2>Request Mentorship</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="guidance-type">What type of guidance do you need?</label>
        <select
          id="guidance-type"
          value={guidanceType}
          onChange={(e) => setGuidanceType(e.target.value)}
        >
          <option value="career-advice">Career Advice</option>
          <option value="resume-review">Resume Review</option>
          <option value="interview-prep">Interview Preparation</option>
        </select>

        <label htmlFor="mentor-select">Select a Mentor (optional)</label>
        <select
          id="mentor-select"
          value={mentorSelect}
          onChange={(e) => setMentorSelect(e.target.value)}
        >
          <option value="auto-match">Auto Match Me</option>
          <option value="Jane Doe">Jane Doe</option>
          <option value="John Smith">John Smith</option>
        </select>

        <button type="submit">Submit Request</button>
      </form>
    </section>
  );
};

export default RequestForm;
