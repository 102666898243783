// src/components/Dashboard/AdminDashboard.js
import React from 'react';
import '../../css/Dashboard.css'; // Import common styles

function AdminDashboard() {
  return (
    <div className="dashboard admin-dashboard">
      <h1>Admin Dashboard</h1>
      <p>Welcome, Admin! Here you can manage users, events, and opportunities.</p>
      {/* Admin specific features */}
    </div>
  );
}

export default AdminDashboard;
