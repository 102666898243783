// src/components/Registration.js
import React from 'react';
import '../css/Registration.css';

function Registration() {
  return (
    <div className="registration">
      <h1>Registration</h1>
      <p>Register for the conference to secure your spot.</p>
      <h2>Registration Fees</h2>
      <ul>
        <li>Early Bird: $200 (Until April 1, 2024)</li>
        <li>Regular: $250 (After April 1, 2024)</li>
        <li>Students: $100</li>
      </ul>
      {/* <button className="register-button">Register Now</button> */}
    </div>
  );
}

export default Registration;
