import React from 'react';
import { Link } from 'react-router-dom';
import '../css/HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <h1>Welcome to the Academic Networking Site</h1>
      <p>Connect with peers, find opportunities, and explore academic events.</p>

      {/* Conference Overview Section */}
      <div className="conference-overview">
        <h2>Conference Overview</h2>
        <p>
          Join us for the Annual Academic Networking Conference 2024. Engage with leading scholars, participate in workshops, and share your research with a global audience.
        </p>
      </div>

      {/* Quick Links Section */}
      <div className="quick-links">
        <h2>Quick Access</h2>
        <div className="links-container">
          <Link to="/call-for-papers" className="quick-link">Call for Papers</Link>
          <Link to="/registration" className="quick-link">Registration</Link>
          <Link to="/conference-schedule" className="quick-link">Conference Schedule</Link>
        </div>
      </div>

      {/* Latest News Section */}
      <div className="latest-news">
        <h2>Latest News & Announcements</h2>
        <p>
          <strong>Announcement:</strong> Early bird registration ends on September 30, 2024! Don’t miss out on exclusive benefits.
        </p>
      </div>

      {/* Additional Links */}
      <div className="home-links">
        <Link to="/login" className="home-link">Login</Link>
        <Link to="/signup" className="home-link">Signup</Link>
      </div>
    </div>
  );
}

export default HomePage;
