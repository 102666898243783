// src/components/Jobs.js
import React from 'react';
import '../../css/Student/Jobs.css'; 

// Sample data for job opportunities
const jobListings = [
  {
    id: 1,
    title: "Software Engineer",
    company: "Tech Innovations",
    location: "Remote",
    description: "Develop and maintain web applications.",
    applyLink: "#", // Replace with the actual link to apply
  },
  {
    id: 2,
    title: "Data Scientist",
    company: "Data Insights LLC",
    location: "New York, NY",
    description: "Analyze data to provide insights for business decisions.",
    applyLink: "#",
  },
  {
    id: 3,
    title: "Project Manager",
    company: "Global Solutions",
    location: "San Francisco, CA",
    description: "Manage projects and coordinate teams to achieve goals.",
    applyLink: "#",
  },
];

function Jobs() {
  return (
    <div className="jobs-page">
      <h1>Job Opportunities</h1>
      <p>Here are some job openings for you:</p>
      <div className="job-listings">
        {jobListings.map((job) => (
          <div key={job.id} className="job-card">
            <h2>{job.title}</h2>
            <p><strong>Company:</strong> {job.company}</p>
            <p><strong>Location:</strong> {job.location}</p>
            <p>{job.description}</p>
            <a href={job.applyLink} className="apply-button">Apply Now</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Jobs;
