import React from "react";
import '../../css/Student/MentorList.css'; 

const MentorList = () => {
  const mentors = [
    { name: "Jane Doe", title: "Software Engineer", experience: "10+ years" },
    { name: "John Smith", title: "Data Scientist", experience: "7+ years" }
  ];

  const handleRequest = () => {
    alert('Requested Mentor Successfully')
  };

  return (
    <section className="mentors-list">
      <h2>Available Mentors</h2>
      <div className="mentor-cards">
        {mentors.map((mentor, index) => (
          <div key={index} className="mentor-card">
            <img src="mentor-photo.jpg" alt="Mentor Photo" />
            <h3>{mentor.name}</h3>
            <p>{mentor.title}, {mentor.experience}</p>
            <button className="request-mentorship-btn" onClick={handleRequest}>Request Mentorship</button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MentorList;
