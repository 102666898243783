// src/pages/Signup.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Updated import statement
import '../css/Signup.css';

function Signup() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('student'); // Default role
  const [signupMessage, setSignupMessage] = useState('');
  const navigate = useNavigate(); 

  const handleSignup = (e) => {
    e.preventDefault();

    // Handle sign-up logic here (validation, API calls, etc.)
    console.log(`Signed up as: ${name}, ${email} with role: ${role}`);

    // Set a signup success message
    setSignupMessage('Signup successful! Redirecting to login...');

    // Redirect after a short delay to allow the user to see the message
    setTimeout(() => {
      navigate('/login');
    }, 2000); // Redirect after 2 seconds
  };

  return (
    <div className='signup-page'>
    <div className="signup-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSignup} className="signup-form">
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        
        <label>Role:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="student">Student</option>
          <option value="academic">Academic</option>
          <option value="admin">Administrator</option>
        </select>
        
        <button type="submit" className="signup-button">Sign Up</button>
      </form>
      {signupMessage && <p className="signup-message">{signupMessage}</p>}
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
    </div>
    </div>
  );
}

export default Signup;
