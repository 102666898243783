// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Updated import statement
import '../css/Login.css';

function Login({ setUserRole }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('student'); // Default role
  const navigate = useNavigate(); // Updated to use useNavigate

  const handleLogin = (e) => {
    e.preventDefault();
    // Simulate login validation here, and store user role in localStorage
    if (email && password) {
      localStorage.setItem('userRole', role);
      
      // Update the role in the state to trigger a re-render of the Header component
      setUserRole(role); 

      // Navigate to the appropriate user dashboard after login
      if (role === 'student') {      
        navigate('/student-dashboard');
      } else if (role === 'admin') {
        navigate('/admin-dashboard');
      } else if (role === 'mentor') {
        navigate('/mentor-dashboard');
      }
    }
  };

  return (
    <div className='login-page'>
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin} className="login-form">
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        
        <label>Role:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="admin">Admin</option>
            <option value="mentor">Mentor</option>
            <option value="student">Student</option>
        </select>
        
        <button type="submit" className="login-button">Login</button>
      </form>
      <p>
        Don't have an account? <Link to="/signup">Sign Up</Link>
      </p>
    </div>
    </div>
  );
}

export default Login;
