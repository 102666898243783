import React from 'react';
import '../css/InterestBasedGroups.css';

function InterestBasedGroups() {
  const groups = [
    'Artificial Intelligence',
    'Machine Learning',
    'Data Science',
    'Web Development',
    'Cybersecurity',
    'Blockchain Technology'
  ];

  return (
    <div className="interest-based-groups">
      <h1>Interest-Based Groups</h1>
      <p>Join groups based on your academic interests and collaborate with peers.</p>
      <ul>
        {groups.map((group, index) => (
          <li key={index}>{group}</li>
        ))}
      </ul>
    </div>
  );
}

export default InterestBasedGroups;
