import React, { useState } from "react";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import NotificationPreferences from "./NotificationPreferences";
import './../css/AccountSettings.css';

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const renderTabContent = () => {
    switch (activeTab) {
      case "profile":
        return <UpdateProfile />;
      case "password":
        return <ChangePassword />;
      case "notifications":
        return <NotificationPreferences />;
      default:
        return <UpdateProfile />;
    }
  };

  return (
    <div className="account-settings">
      <h1>Account Settings</h1>

      <div className="settings-nav">
        <button
          className={activeTab === "profile" ? "active" : ""}
          onClick={() => setActiveTab("profile")}
        >
          Profile Details
        </button>
        <button
          className={activeTab === "password" ? "active" : ""}
          onClick={() => setActiveTab("password")}
        >
          Change Password
        </button>
        <button
          className={activeTab === "notifications" ? "active" : ""}
          onClick={() => setActiveTab("notifications")}
        >
          Notification Preferences
        </button>
      </div>

      <div className="settings-content">{renderTabContent()}</div>
    </div>
  );
};

export default AccountSettings;
