import React, { useState } from "react";
import './../css/UpdateProfile.css';

const UpdateProfile = () => {
  const [name, setName] = useState("John Doe");
  const [email, setEmail] = useState("john.doe@example.com");

  const handleSaveProfile = () => {
    console.log("Profile Updated", { name, email });
    alert("Profile updated successfully!");
  };

  return (
    <section className="update-profile">
      <h2>Update Profile</h2>
      <div className="profile-form">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button className="save-profile-btn" onClick={handleSaveProfile}>
          Save Changes
        </button>
      </div>
    </section>
  );
};

export default UpdateProfile;
