import React from "react";
import '../../css/Student/SessionHistory.css'; 

const SessionHistory = () => {
  const sessions = [
    {
      mentor: "Jane Doe",
      date: "15th October 2024",
      feedback: "It was a great session, helped me prepare for interviews!"
    }
    // Add more sessions here
  ];
  const handleFeedback = () => {
    alert("Feedback Sent");
  };

  return (
    <section className="session-history">
      <h2>Your Mentorship Sessions</h2>
      {sessions.map((session, index) => (
        <div key={index} className="session-card">
          <h3>Session with {session.mentor}</h3>
          <p>Date: {session.date}</p>
          <p>Feedback: {session.feedback}</p>
          <button className="leave-feedback-btn" onClick={handleFeedback}>Leave Feedback</button>
        </div>
      ))}
    </section>
  );
};

export default SessionHistory;
