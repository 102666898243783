// src/components/ConferenceSchedule.js
import React from 'react';
import '../css/ConferenceSchedule.css';

function ConferenceSchedule() {
  return (
    <div className="conference-schedule">
      <h1>Conference Schedule</h1>
      <h2>Day 1: June 10, 2024</h2>
      <ul>
        <li>09:00 AM - Opening Remarks</li>
        <li>10:00 AM - Keynote Speaker: Dr. Jane Doe</li>
        <li>11:00 AM - Break</li>
        <li>11:30 AM - Session 1: Topic A</li>
        <li>01:00 PM - Lunch</li>
        <li>02:00 PM - Session 2: Topic B</li>
      </ul>
      <h2>Day 2: June 11, 2024</h2>
      <ul>
        <li>09:00 AM - Workshop: Hands-on Coding</li>
        <li>12:00 PM - Networking Lunch</li>
        <li>01:30 PM - Panel Discussion: Future of Academia</li>
        <li>03:00 PM - Closing Ceremony</li>
      </ul>
    </div>
  );
}

export default ConferenceSchedule;
