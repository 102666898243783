// src/components/Opportunities.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Opportunities.css';

function Opportunities() {
  const navigate = useNavigate(); // Initialize navigate

  // Function to navigate to the Job page
  const handleJobClick = () => {
    navigate('/jobs');
  };

  // Function to navigate to the Internship page
  const handleInternshipClick = () => {
    navigate('/internships');
  };

  return (
    <div className="opportunities-page">
      <h1>Job and Internship Opportunities</h1>
      <p>Discover a variety of job and internship openings in your field of interest.</p>
      <div className="opportunity-list">
      <button className="opportunity-card" onClick={handleJobClick}>
          <h2>Job Board</h2>
          <p>Find job openings and apply directly through our platform.</p>
        </button>
        <button className="opportunity-card" onClick={handleInternshipClick}>
          <h2>Internship Board</h2>
          <p>Explore internships that can help kickstart your career.</p>
        </button>
      </div>
    </div>
  );
}

export default Opportunities;
