// src/components/Internships.js
import React from 'react';
import '../../css/Student/Internships.css'; 

// Sample data for internship opportunities
const internshipListings = [
  {
    id: 1,
    title: "Marketing Intern",
    company: "Creative Agency",
    location: "Los Angeles, CA",
    description: "Assist in developing marketing strategies and campaigns.",
    applyLink: "#", // Replace with the actual link to apply
  },
  {
    id: 2,
    title: "Web Development Intern",
    company: "Web Solutions Inc.",
    location: "Remote",
    description: "Help develop and maintain our website and applications.",
    applyLink: "#",
  },
  {
    id: 3,
    title: "Data Analyst Intern",
    company: "Data Insights Corp.",
    location: "Chicago, IL",
    description: "Support the data analysis team in various projects.",
    applyLink: "#",
  },
];

function Internships() {
  return (
    <div className="internships-page">
      <h1>Internship Opportunities</h1>
      <p>Here are some internships to help kickstart your career:</p>
      <div className="internship-listings">
        {internshipListings.map((internship) => (
          <div key={internship.id} className="internship-card">
            <h2>{internship.title}</h2>
            <p><strong>Company:</strong> {internship.company}</p>
            <p><strong>Location:</strong> {internship.location}</p>
            <p>{internship.description}</p>
            <a href={internship.applyLink} className="apply-button">Apply Now</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Internships;
