// src/components/NetworkingPage.js
import React from 'react';
import '../css/NetworkingPage.css';

function NetworkingPage() {
  return (
    <div className="networking-page">
      <h1>Networking</h1>
      <p>Connect with academics through groups and forums.</p>
      <div className="networking-links">
        <a href="/interest-groups">Groups</a>
        <a href="/public-forums">Forums</a>
      </div>
    </div>
  );
}

export default NetworkingPage;
