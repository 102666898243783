// src/components/CallForPapers.js
import React from 'react';
import '../css/CallForPapers.css';

function CallForPapers() {
  return (
    <div className="call-for-papers">
      <h1>Call for Papers</h1>
      <p>We invite submissions for our upcoming conference. Please follow the guidelines below:</p>
      <ul>
        <li>Paper Submission Deadline: March 15, 2024</li>
        <li>Notification of Acceptance: April 15, 2024</li>
        <li>Camera Ready Submission: May 1, 2024</li>
      </ul>
      <h2>Submission Guidelines</h2>
      <p>All submissions must be original and not exceed 10 pages in length.</p>
      <p>Please submit your papers in PDF format.</p>
    </div>
  );
}

export default CallForPapers;
