// src/components/Dashboard/MentorDashboard.js
import React from 'react';
import '../../css/Dashboard.css'; // Import common styles

function MentorDashboard() {
  return (
    <div className="dashboard mentor-dashboard">
      <h1>Mentor Dashboard</h1>
      <p>Welcome, Mentor! Here you can manage your mentees and resources.</p>
      {/* Mentor specific features */}
    </div>
  );
}

export default MentorDashboard;
