// src/App.js
import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import NetworkingPage from './components/NetworkingPage';
import CareerDevelopment from './components/CareerDevelopment';
import Opportunities from './components/Opportunities';
import EventsPage from './components/EventsPage';
import MentorshipProgram from './components/MentorshipProgram';
import AccountSettings from './components/AccountSettings';
import ContactUs from './components/ContactUs';
import Login from './components/Login';
import Signup from './components/Signup';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import MentorDashboard from './components/Dashboard/MentorDashboard';
import StudentDashboard from './components/Dashboard/StudentDashboard';
import CallForPapers from './components/CallForPapers';
import Registration from './components/Registration';
import ConferenceSchedule from './components/ConferenceSchedule';
import InterestBasedGroups from './components/InterestBasedGroups';
import PublicForums from './components/PublicForums';
import About from './components/About';
import Jobs from './components/Student/Jobs';
import Internships from './components/Student/Internships';
import StudentMentorship from './components/Student/StudentMentorship';
import MentorMentorship from './components/Mentor/MentorshipPageMentor';
import './css/global.css';

function App() {
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'));
   // Check if the user role is stored in localStorage on app load
   useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (storedRole) {
      setUserRole(storedRole);
    }
  }, []);
  return (
    <Router>
      <div className="App">
        <Header userRole={userRole} setUserRole={setUserRole} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/networking" element={<NetworkingPage />} />
          <Route path="/career-development" element={<CareerDevelopment />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/mentorship" element={<MentorshipProgram />} />
          <Route path="/settings" element={<AccountSettings />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/login" element={<Login setUserRole={setUserRole}/>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/admin-dashboard" element={<AdminDashboard/>} />
          <Route path="/mentor-dashboard" element={<MentorDashboard/>} />
          <Route path="/student-dashboard" element={<StudentDashboard/>} />
          <Route path="/call-for-papers" element={<CallForPapers/>} />
          <Route path="/registration" element={<Registration/>} />
          <Route path="/conference-schedule" element={<ConferenceSchedule/>} />
        <Route path="/interest-groups" element={<InterestBasedGroups/>} />
        <Route path="/public-forums" element={<PublicForums/>} />
        <Route path="/jobs" element={<Jobs/>} />
        <Route path="/internships" element={<Internships/>} />
        <Route path="/student-mentorship" element={<StudentMentorship/>} />
        <Route path="/mentor-mentorship" element={<MentorMentorship/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
