import React from "react";
import MentorshipRequests from "./MentorshipRequests";
import SessionManagement from "./SessionManagement";
import CommunicationPanel from "./CommunicationPanel";
import '../../css/Mentor/MentorshipPageMentor.css';  // Import CSS for Mentor Mentorship Page

const MentorshipPageMentor = () => {
  return (
    <div className="mentorship-page-mentor">
      {/* Hero Section */}
      <section className="mentor-hero">
        <h1>Welcome, Mentor</h1>
        <p>Manage your mentorship sessions and guide students to success.</p>
      </section>

      {/* Mentorship Requests */}
      <MentorshipRequests />

      {/* Session Management */}
      <SessionManagement />

      {/* Communication Panel */}
      <CommunicationPanel />
    </div>
  );
};

export default MentorshipPageMentor;
