// src/components/EventsPage.js
import React from 'react';
import '../css/EventsPage.css';

function EventsPage() {
  return (
    <div className="events-page">
      <h1>Academic Events</h1>
      <p>Stay up-to-date with upcoming academic conferences, workshops, and seminars.</p>
      <div className="event-list">
        <div className="event-card">
          <h2>Conference on Data Science</h2>
          <p>Date: October 10, 2024</p>
        </div>
        <div className="event-card">
          <h2>Workshop on Machine Learning</h2>
          <p>Date: November 5, 2024</p>
        </div>
      </div>
    </div>
  );
}

export default EventsPage;
