import React from "react";
import '../../css/Mentor/MentorshipRequests.css';  // Import CSS for Mentorship Requests

const MentorshipRequests = () => {
  const requests = [
    { student: "Alice Johnson", guidance: "Career Advice", requestDate: "2024-10-15" },
    { student: "Bob Williams", guidance: "Interview Preparation", requestDate: "2024-10-18" }
  ];

  const handleRequest = () => {
    alert('Request Accepted')
  };

  return (
    <section className="mentorship-requests">
      <h2>Mentorship Requests</h2>
      <div className="requests-list">
        {requests.map((request, index) => (
          <div key={index} className="request-card">
            <h3>{request.student}</h3>
            <p>Guidance: {request.guidance}</p>
            <p>Requested On: {request.requestDate}</p>
            <button className="accept-request-btn" onClick={handleRequest}>Accept Request</button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MentorshipRequests;
