import React from 'react';
import '../css/PublicForums.css';

function PublicForums() {
  const discussions = [
    { title: 'The Future of AI in Education', replies: 12 },
    { title: 'Best Practices in Data Science', replies: 7 },
    { title: 'Web Development Trends in 2024', replies: 5 },
    { title: 'How to Secure Your Online Presence', replies: 8 }
  ];

  return (
    <div className="public-forums">
      <h1>Public Forums</h1>
      <p>Join academic discussions and share your insights.</p>
      <ul>
        {discussions.map((discussion, index) => (
          <li key={index}>
            <strong>{discussion.title}</strong> - {discussion.replies} replies
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PublicForums;
