// src/components/About.js
import React from 'react';
import '../css/About.css'; // Optionally, create a CSS file for custom styles

function About() {
  return (
    <div className="about-page">
      <h1>About Academic Networking</h1>
      <p>
        Welcome to the Academic Networking site! Our platform is designed to connect graduate students,
        mentors, and academics, fostering collaboration, networking, and knowledge-sharing.
      </p>
      <h2>Our Mission</h2>
      <p>
        The mission of Academic Networking is to build a bridge between students and professionals in academia.
        We aim to provide a robust platform where individuals can network, share opportunities, seek mentorship,
        and grow in their academic careers.
      </p>
      <h2>Features</h2>
      <ul>
        <li>Interest-based groups for discussion</li>
        <li>Public forums for academic collaboration</li>
        <li>Mentorship programs for students</li>
        <li>Career development resources and job opportunities</li>
        <li>Event management and participation</li>
      </ul>
      <h2>Join Us</h2>
      <p>
        Whether you are a student looking for guidance, a mentor wanting to share your expertise, or a professional
        seeking collaboration opportunities, Academic Networking is the place for you. Join today and be part of a
        growing academic community!
      </p>
    </div>
  );
}

export default About;
