// src/components/Dashboard/StudentDashboard.js
import React from 'react';
import '../../css/Dashboard.css'; // Import common styles
import { Link } from 'react-router-dom';

function StudentDashboard() {
  return (
    <div className="dashboard student-dashboard">
      <h1>Student Dashboard</h1>
      <p>Welcome, Student! Here you can find opportunities and resources.</p>
      <div className="dashboard-buttons">
        <Link to="/interest-groups" className="dashboard-button">Interest-Based Groups</Link>
        <Link to="/public-forums" className="dashboard-button">Public Forums</Link>
      </div>
    </div>
  );
}

export default StudentDashboard;
