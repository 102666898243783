import React, { useState } from "react";
import '../../css/Student/MessagePanel.css'; 

const MessagePanel = () => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    alert("Message Sent");
    setMessage(""); // Clear the message after sending
  };

  return (
    <section className="message-panel">
      <h2>Communicate with Your Mentor</h2>
      <textarea
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button className="send-message-btn" onClick={handleSend}>
        Send
      </button>
    </section>
  );
};

export default MessagePanel;
