// src/components/Header.js
import React from 'react';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../css/Header.css';

function Header({ userRole, setUserRole }) {
  const navigate = useNavigate(); // useNavigate to redirect on logout
  
  // Watch for changes in localStorage and update the userRole state accordingly
  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (storedRole) {
      setUserRole(storedRole);
    }
  }, [setUserRole]);

  // Function to redirect to dashboard based on user role
  const redirectToDashboard = () => {
    if (userRole === 'student') {
      navigate('/student-dashboard');
    } else if (userRole === 'admin') {
      navigate('/admin-dashboard');
    } else if (userRole === 'mentor') {
      navigate('/mentor-dashboard');
    } else {
      navigate('/');
    }
  };

  // Function to handle logout
  const handleLogout = () => {
    // Clear the user role from localStorage
    localStorage.removeItem('userRole');
     // Clear the userRole state to re-render the component
     setUserRole(null);
    // Redirect to the login page
    navigate('/login');
  };

  return (
    <header className="header">
      <h1 className="logo" onClick={redirectToDashboard} style={{ cursor: 'pointer' }}>Academic Networking</h1>
      <nav className="nav-links">
        {!userRole && (
          <NavLink 
            exact="true" 
            to="/" 
            className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
          >
            Home
          </NavLink>
        )}
        {/* Links for unauthenticated users */}
        {!userRole && (
          <>
            <NavLink 
              to="/about" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              About
            </NavLink>
            <NavLink 
              to="/contact" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Contact Us
            </NavLink>
            <NavLink 
              to="/login" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Login
            </NavLink>
            <NavLink 
              to="/signup" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Sign Up
            </NavLink>
          </>
        )}

        {/* Links for authenticated users */}
        {userRole && (
          <>
            <NavLink 
              to="/networking" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Networking
            </NavLink>
            <NavLink 
              to="/career-development" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Career Development
            </NavLink>
            <NavLink 
              to="/events" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Events
            </NavLink>

            {/* Student-specific links */}
            {userRole === 'student' && (
              <>
                <NavLink 
                  to="/opportunities" 
                  className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
                >
                  Opportunities
                </NavLink>
                <NavLink 
                  to="/student-mentorship" 
                  className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
                >
                  Mentorship
                </NavLink>
              </>
            )}

            {/* Mentor-specific links */}
            {userRole === 'mentor' && (
              <NavLink 
                to="/mentor-mentorship" 
                className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
              >
                Mentorship
              </NavLink>
            )}

            {/* Admin-specific links */}
            {userRole === 'admin' && (
              <>
                <NavLink 
                  to="/opportunities" 
                  className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
                >
                  Opportunities
                </NavLink>
              </>
            )}

            {/* Common settings and contact links for authenticated users */}
            <NavLink 
              to="/settings" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Account Settings
            </NavLink>
            <NavLink 
              to="/contact" 
              className={({ isActive }) => isActive ? "nav-item active-link" : "nav-item"}
            >
              Contact Us
            </NavLink>

            {/* Logout button */}
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </>
        )}
      </nav>
    </header>
  );
}

export default Header;
